<template>
    <div class="container-fluid">
        <Quote title="Bu yerda biz bilan bog'lanishni istaganlardan kelgan xabarlarni ko'rishingiz mumkin."/>
        <GoBack next="Xabarlar"/>

        <div ref="top"></div>
        <div class="row justify-content-center mb-0">
            <div class="col-11 col-md-11 col-lg-11 col-xl-11 col-xxl-11 overflow-auto">
                <table class="table table-hover border">
                    <thead>
                    <tr>
                        <th class="col-1 id d-none d-xl-table-cell">
                            <input
                                v-model="searchRequestForCallsFilter.id"
                                @keyup.enter="searchRequestForCallInfo"
                                type="number"
                                placeholder="Id"
                                style="width: 50px;border-radius: 5px;"
                            />
                        </th>
                        <th class="col name">
                            <input
                                v-model="searchRequestForCallsFilter.fullName"
                                @keyup.enter="searchRequestForCallInfo"
                                type="text"
                                placeholder="Ism sharfi"
                                style="width: 100px;border-radius: 5px;"
                                class="inputs"
                            />
                        </th>
                        <th class="col surname d-none d-md-table-cell">
                            <input
                                v-model="searchRequestForCallsFilter.phone"
                                @keyup.enter="searchRequestForCallInfo"
                                type="text"
                                placeholder="Telefon raqam"
                                style="width: 100px;border-radius: 5px;"
                            />
                        </th>
                        <th class="col surname d-none d-md-table-cell">
                            <input
                                v-model="searchRequestForCallsFilter.telegramUsername"
                                @keyup.enter="searchRequestForCallInfo"
                                type="text"
                                placeholder="Telegram"
                                style="width: 100px;border-radius: 5px;"
                            />
                        </th>
                        <th class="col surname d-none d-md-table-cell">
                            <input
                                v-model="searchRequestForCallsFilter.message"
                                @keyup.enter="searchRequestForCallInfo"
                                type="text"
                                placeholder="Xabar"
                                style="width: 100px;border-radius: 5px;"
                            />
                        </th>
                        <th class="col name">
                            <input
                                v-model="searchRequestForCallsFilter.createdAt"
                                @keyup.enter="searchRequestForCallInfo"
                                type="text"
                                placeholder="Yaratilgan sana"
                                style="width: 100px;border-radius: 5px;"
                                class="inputs"
                            />
                        </th>
                        <th class="col status d-table-cell">Javob berilgan sana</th>
                        <th class="col-1 id d-none d-xl-table-cell">
                            <select
                                v-model="searchRequestForCallsFilter.isResponseSent"
                                class="mb-2"
                                @change="searchRequestForCallInfo"
                                style="width: 110px;border-radius: 5px;"
                            >
                                <option selected :value="null">Barchasi</option>
                                <option value="true">Javob berilgan</option>
                                <option value="false">Kutilmoqda</option>
                            </select>
                        </th>
                        <b-th class="table-icons" style="width: 100px">
                            <b-button
                                v-b-tooltip.hover
                                title="Izlash"
                                class="border-0 p-0"
                                style="background-color: #E5E5E5; font-size:1.6vw;"
                            >
                                    <span>
                                      <img
                                          @click="searchRequestForCallInfo"
                                          style=" height: auto; cursor: pointer; font-size:1.6vw;"
                                          src="../assets/search-icon.png"
                                          alt=""
                                      >
                                    </span>
                            </b-button>
                            <b-button
                                v-b-tooltip.hover
                                title="Tozalash"
                                class="border-0 p-1 p-sm-0 ms-sm-3"
                                style="background-color: #E5E5E5; font-size:1.7vw;"
                            >
                                    <span>
                                      <img
                                          @click="resetSaleSearchInfo"
                                          style=" height: auto; cursor: pointer; font-size:1.7vw;"
                                          src="../assets/eraser-icon.png"
                                          alt=""
                                      >
                                    </span>
                            </b-button>
                        </b-th>
                    </tr>
                    </thead>

                    <tbody>
                    <tr
                        class="pl-3 pt-3 pb-3"
                        v-for="requestItem in getRequestList"
                        :key="requestItem.id"
                    >
                        <td class="d-none d-xl-table-cell">{{ requestItem.id }}</td>
                        <td>{{ requestItem.fullName }}</td>
                        <td class="d-none d-md-table-cell">{{ requestItem.phone }}</td>
                        <td class="d-none d-md-table-cell">{{ requestItem.telegramUsername }}</td>
                        <td class="d-none d-md-table-cell">{{ requestItem.message }}</td>
                        <td class="d-none d-sm-table-cell">
                            <div>
                                <span class="ml-2 text-nowrap">
                                    {{ requestItem.createdAt.slice(11, 19)}}, {{ requestItem.createdAt.slice(0, 10).split('-').reverse().join('-') }}
                                 </span>
                            </div>
                        </td>
                        <td class="d-none d-xl-table-cell">
                            <div v-if="requestItem.responseSentAt" class="text-nowrap" style="color: #03ab18">{{ requestItem.responseSentAt.slice(11, 19)}}, {{ requestItem.responseSentAt.slice(0, 10).split('-').reverse().join('-') }}</div>
                            <div v-else style="color: red">Kutilmoqda</div>
                        </td>
                        <td class="d-table-cell">
                            <button
                                v-if="!requestItem.responseSentAt"
                                @click="makeResponseSent(requestItem.id)"
                                type="button"
                                class="btn btn-primary agree-btn"
                            >
                                Javob berilgan qilib belgilash
                            </button>

<!--                            <button-->
<!--                                v-else-->
<!--                                @click="disagree(comment.id)"-->
<!--                                type="button"-->
<!--                                class="btn btn-danger agree-btn"-->
<!--                            >-->
<!--                                Rad etish-->
<!--                            </button>-->
                        </td>
                        <td >
                            <img
                                @click="showModal(requestItem.id)"
                                class="p-0 ms-sm-3 trash"
                                src="../assets/trash.png"
                                alt="trash-icon"
                            >
                        </td>
                    </tr>
                    </tbody>
                </table>

                <div>
                    <b-modal ref="modal-delete" hide-footer title="Kadirov.Dev">
                        <div class="d-block text-center">
                            <h3>Ushbu izohni rostdan ham o'chirmoqchimisiz?</h3>
                        </div>
                        <b-button
                            class="mt-3"
                            style="width: 100px"
                            variant="btn btn-primary"
                            size="sm"
                            @click="hideErrorModal"
                        >
                            Yo'q
                        </b-button>
                        <b-button class="mt-3 float-end" style="width: 100px" variant="btn btn-danger" size="md"
                                  @click="deleteRequestForCall">
                            Ha
                        </b-button>
                    </b-modal>
                </div>

                <div>
                    <b-modal ref="modal-success" hide-footer title="Kadirov.Dev">
                        <div class="d-block text-center">
                            <h3>Izoh o'chirildi</h3>
                        </div>
                        <b-button
                            class="mt-3 float-end"
                            style="width: 100px"
                            variant="btn btn-primary"
                            size="md"
                            @click="hideErrorModal"
                        >
                            OK
                        </b-button>
                    </b-modal>
                </div>

            </div>
        </div>
        <b-overlay :show="show" no-wrap>
        </b-overlay>

        <div>
            <b-pagination
                v-if="getRequestListTotalCount > 19"
                v-model="currentPage"
                pills
                align="center"
                :per-page="20"
                :total-rows="getRequestListTotalCount"
                :click-handler="fetchRequestForCallsByPage"
                @input="fetchRequestForCallsByPage"
            >
            </b-pagination>
        </div>
    </div>
</template>

<script>
import GoBack from "../components/GoBack";
import Quote from "../components/Quote";
import {mapActions, mapGetters} from "vuex";

export default {
    name: "RequestForCallPage",
    components: {
        Quote,
        GoBack
    },
    data() {
        return {
            show: true,
            // url: process.env.VUE_APP_API_URL, //todo
            requestForCallId: null,
            currentPage: 1,
            searchRequestForCallsFilter: {
                id: null,
                fullName: '',
                phone: '',
                telegramUsername: '',
                message: '',
                createdAt: '',
                page: 1,
                isResponseSent: null,
                isDeleted: null
            },
            options: [
                {text: 'Barchasi', value: null},
                {text: 'Javob berilgan', value: true},
                {text: 'Kutulmoqda', value: false}
            ],
        }
    },
    watch: {
        '$route.params.page': {
            handler: function (val) {
                this.currentPage = val ? val : 1
                this.fetchRequestForCalls(this.currentPage)
            },
            deep: true,
            immediate: true
        }
    },
    methods: {
        ...mapActions([
            'fetchRequestForCalls',
            'searchRequestForCalls',
            'changeRequestForCallResponseSent',
            'makeRequestForCallHasSeen',
            'deleteOneRequestForCall'
        ]),
        fetchRequestForCallsByPage() {
            this.$router.push('/request-for-call-list/page/' + this.currentPage)
            this.$refs.top.scrollIntoView()
        },
        searchRequestForCallInfo() {
            this.show = true
            this.searchRequestForCalls(this.searchRequestForCallsFilter)
                .catch((err) => {
                    console.log(err)
                })
                .finally(() => {
                    this.show = false
                });
        },
        resetSaleSearchInfo() {
            Object.assign(this.$data, this.$options.data.call(this));
            this.fetchRequestForCall()
        },
        fetchRequestForCall() {
            this.show = true
            console.log(this.searchRequestForCallsFilter)
            this.searchRequestForCalls(this.searchRequestForCallsFilter)
                .catch((err) => {
                    console.log(err)
                })
                .finally(() => {
                    this.show = false
                });
        },
        makeResponseSent(id) {
            this.changeRequestForCallResponseSent(id)
                .then(() => {
                    this.fetchRequestForCalls(this.currentPage)
                })
        },
        deleteRequestForCall() {
            this.deleteOneRequestForCall(this.requestForCallId)
                .then(() => {
                    this.$refs['modal-delete'].hide()
                    setTimeout(this.hideErrorModal, 3000)
                    this.$refs['modal-success'].show()
                    setTimeout(this.hideErrorModal, 3000)
                    this.fetchRequestForCalls(this.currentPage)
                })
        },
        showModal(id) {
            this.requestForCallId = id
            this.$refs['modal-delete'].show()
        },
        hideErrorModal() {
            this.$refs['modal-delete'].hide()
            this.$refs['modal-success'].hide()
        },
    },
    computed: {
        ...mapGetters(['getRequestList', 'getRequestListTotalCount', 'getAllNewRequestForCallIds'])
    },
    mounted() {
        this.show = true
        this.fetchRequestForCalls(this.currentPage).then(() => {
            this.makeRequestForCallHasSeen(this.getAllNewRequestForCallIds)
        })
        // this.makeRequestForCallHasSeen()
        // this.mediaFetch() //todo
        this.show = false
    }
}
const tr = document.querySelectorAll('tr');
for (let i = 0; i < tr.length; i++) {
    tr[i].setAttribute('class', 'align-top');
}

</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Comfortaa&family=Open+Sans:wght@400;600&display=swap');

* {
    margin: 0;
    padding: 0;
}

img {
    width: 20px;
    height: 20px;
    margin-bottom: 1rem;
}

.trash {
    cursor: pointer;
}

.agree-btn {
    font-size: 12px;
    font-family: Comfortaa, cursive;
    padding: 5px;
}

.row {
    margin-top: 111px;
    margin-bottom: 111px;
}

table {
    margin-bottom: 50px;
}

input::placeholder {
    padding: 0.5rem;
    font-size: 12px;
}

input {
    border: 1px solid black;
    height: 25px;
}

input:focus {
    border: none;
    outline: 2px solid #b505b4;
}

select {
    box-shadow: 0 0 0 1px black;
    padding-left: 10px;
    height: 25px;
}

table th {
    height: 60px;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    text-align: left;

    background-color: #E5E5E5;
    color: rgba(0, 0, 0, 0.5);
}

tbody tr {
    height: 60px;
    vertical-align: text-top;
}

table tr select {
    margin-right: 10px;
    border: none;
}

.id {
    width: 98px;
    height: 56px;
    line-height: 56px;
}

.name {
    width: 111px;
    height: 56px;
    line-height: 56px;
}

.comment {
    height: 56px;
    line-height: 56px;
}

.surname {
    width: 129px;
    height: 56px;
    line-height: 56px;
}

.status {
    width: 170px;
    height: 56px;
    line-height: 56px;
}

table tbody tr td {
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 1px;

    background-color: #FFFFFF;
    color: #000000;
}

input {
    border: 1px solid black;
}
</style>
